@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Urbanist", sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

li {
  margin-left: 20px;
}
ul li {
  list-style-type: disc !important;
}
ol li {
  list-style-type: decimal !important;
}

/* container ------------------------------------------------------*/
.main-container {
  @apply w-10/12 lg:w-11/12 mx-auto overflow-hidden;
}
.center {
  @apply flex justify-center items-center;
}

/* font sizes -----------------------------------------------------*/
.theme-lgtext {
  @apply text-7xl lg:text-9xl;
}

.theme-h1 {
  @apply text-[40px] lg:text-7xl;
}

.theme-h2 {
  @apply text-3xl lg:text-5xl;
}

.theme-h3 {
  @apply text-2xl lg:text-4xl;
}

.theme-p1 {
  @apply text-[18px] lg:text-2xl;
}

.theme-p2 {
  @apply text-base lg:text-xl;
}

.theme-p3 {
  @apply text-sm lg:text-base;
}

.theme-italic {
  @apply italic text-theme-pink;
}

/* buttons ---------------------------------------------------------------------------- */
.theme-btn {
  @apply py-4 px-8 lg:py-5 lg:px-16 rounded-full center min-w-[100px] md:min-w-[130px] lg:min-w-[250px] text-sm lg:text-2xl font-semibold;
}
/* .linked-btn {
  @apply inline-block cursor-pointer py-4 px-8 lg:py-5 lg:px-16 rounded-full center text-sm lg:text-2xl font-semibold  text-theme-white dark:text-theme-black transition after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-theme-black dark:after:bg-theme-white after:rounded-full after:-z-20 before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-0 before:h-full before:bg-theme-pink before:rounded-full before:-z-10 before:transition hover:before:w-full;
} */
.linked-btn {
  @apply inline-block py-4 px-8 lg:py-5 lg:px-16 text-sm lg:text-xl font-semibold rounded-full text-theme-white dark:text-theme-black hover:text-theme-white transition relative overflow-hidden z-[1] cursor-pointer shadow-lg;
}
/* shadow-[20px_20px_60px_rgba(255,0,173,0.15),_-20px_-20px_60px_rgba(247,247,247,0.15)] dark:shadow-[20px_20px_60px_rgba(255,0,173,0.15),_-20px_-20px_60px_rgba(30,30,30,0.15)]; */
.linked-btn:after {
  @apply bg-theme-pink dark:bg-theme-white rounded-full;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.linked-btn:before {
  @apply bg-theme-black dark:bg-theme-pink rounded-full;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  transition: all 0.3s;
  z-index: -1;
}
.linked-btn:hover {
  color: #fff;
}
.linked-btn:hover:before {
  width: 100%;
}
.view-case-btn {
  @apply lg:hidden w-10 h-max bg-theme-gray aspect-square rounded-full p-3 shadow-sm bg-opacity-50 dark:bg-opacity-30 group-hover:rotate-45 transition;
}

/* glassmorphism ----------------------------------------------------------------------*/
.glass-effect {
  /* From https://css.glass */
  /* background: rgba(254, 254, 254, 0.6); */
  /* border-radius: 16px; */
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(6.9px);
  -webkit-backdrop-filter: blur(6.9px);
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
}

/* marquee-animation--------------------------------------------------------------------- */
@media only screen and (min-width: 1024px) {
  .marquee-container {
    @apply relative lg:min-h-[240px] overflow-hidden;
    white-space: nowrap;
  }
  .marquee {
    @apply absolute top-0 left-0 overflow-hidden flex flex-col lg:flex-row gap-3;
    animation: marquee 35s linear infinite;
    /* animation-delay: 2s; */
  }
}
@media only screen and (max-width: 1023px) {
  .marquee {
    @apply grid md:grid-cols-2 gap-5;
  }
}
.marquee-element {
  @apply w-full lg:w-[350px] lg:mr-3 rounded-2xl overflow-hidden relative cursor-pointer;
  float: left;
}
.marquee-element img {
  @apply group-hover:brightness-50 transition;
}
.marquee-element_text {
  @apply absolute bottom-7 left-7 theme-h3 text-theme-pure-white lg:scale-0 group-hover:scale-105 transition;
}
.marquee-element_arrow {
  @apply absolute bottom-7 right-7 w-4 lg:group-hover:w-7 group-hover:rotate-45 transition;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

/* for snap scroll--------------------------------------------------------------------- */
@media only screen and (min-width: 1024px) {
  .scroller {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
  .scroller::-webkit-scrollbar {
    display: none;
  }
  .scroller section {
    scroll-snap-align: center;
  }
}

/* for linkhover animation ----------------------------------------------------------------- */
.link-hover {
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.3s;
  transform-origin: left;
}
.link-hover:hover {
  color: currentColor;
  background-size: 100% 100%;
  transform-origin: right;
}

/* for scrollbar ---------------------------------------------------------------------------- */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* for seo steps animation ------------------------------------------------------------------- */
.steps_text-container {
  @apply flex justify-center py-5 lg:py-10 mx-auto lg:w-full text-left lg:mt-10 gap-5;
}
.steps_text-container:last-child {
  @apply pb-0;
}
.steps_text-numbering {
  @apply text-theme-pink theme-p1;
}
.steps_text-right {
  @apply border-b-[1px] border-b-theme-pink;
}
.steps_text-heading {
  @apply theme-h3 font-semibold pb-5;
}
.steps_text-paragraph {
  @apply theme-p2 lg:max-w-[700px] pb-8 text-theme-gray dark:text-[#c2c2c2];
}
.steps_chart-blue-blob {
  @apply absolute bottom-0 left-0 h-1/2 aspect-square  bg-theme-blue blur-3xl dark:blur-2xl opacity-10 rounded-full;
}
.steps_chart-pink-blob {
  @apply absolute top-0 right-0 h-1/3 aspect-square bg-theme-pink blur-3xl dark:blur-2xl opacity-10 dark:opacity-[0.06] rounded-full;
}
@media only screen and (min-width: 1024px) {
  .steps_scroller {
    height: 70vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
  .steps_scroller::-webkit-scrollbar {
    display: none;
  }
  .steps_scroller .steps_text-container {
    scroll-snap-align: center;
  }
}

/* top rated container  */
.top-rated-container {
  @apply flex items-center justify-center lg:justify-start gap-2 mb-5 lg:mb-6 lg:bg-theme-blue dark:lg:bg-theme-gray lg:py-3 lg:px-5 rounded-full lg:w-max !bg-opacity-20 dark:bg-opacity-30 
}

/* number counter */
.number_counter-container {
  @apply grid grid-cols-3 lg:flex lg:justify-start lg:w-max gap-5 md:gap-8 lg:gap-8 2xl:gap-16 mx-auto lg:mx-0;
}
.number_counter {
  @apply flex flex-col items-center lg:items-start bg-theme-gray lg:bg-inherit bg-opacity-20 md:w-auto md:min-w-[130px] py-3 lg:py-0 rounded-2xl shadow-sm;
}
.number_counter .theme-h1 {
  @apply font-semibold text-3xl md:text-5xl 2xl:text-7xl;
}
.number_counter .desc {
  @apply pt-2 lg:pt-3 text-center lg:text-left text-sm lg:text-xl;
}

/* social icons ---------------------------------------------------------------------- */
.social-icons_wrapper {
  @apply w-[40px] lg:w-[50px] opacity-90 dark:opacity-75 hover:opacity-100 hover:dark:opacity-100 transition-all duration-300 shadow-[4px_4px_20px_rgba(255,0,173,0.06),_-4px_-4px_20px_rgba(247,247,247,0.06)] dark:shadow-[4px_4px_20px_rgba(255,0,173,0.06),_-4px_-4px_20px_rgba(30,30,30,0.06)] rounded-full;
}
.social-icons_wrapper a {
  @apply w-full h-full relative center after:content-[''] after:bg-theme-pink after:absolute after:-top-[1px] after:left-[50%] after:block after:w-0 after:h-0 after:rounded-full after:transition-all after:duration-300 hover:after:w-full hover:after:h-full hover:after:-ml-[50%] after:-z-[1] rounded-full;
}
.social-icons_wrapper circle {
  @apply fill-theme-pink dark:fill-theme-gray opacity-10 dark:opacity-30 group-hover:opacity-100 dark:group-hover:opacity-100;
}

/* case study template ------------------------------------------------------------------- */
.case_study-section1 {
  @apply text-left theme-lgtext flex flex-col font-semibold;
}
.case_study-projectImg-lg {
  @apply relative overflow-hidden w-full h-[50vh] lg:h-screen mt-16 hidden lg:flex;
}
.case_study-projectImg-mbl {
  @apply w-full overflow-hidden  lg:hidden mt-14;
}
/* .project_details ol,.project_details p {
  @apply text-theme-gray dark:text-[#aaaaaa]
} */

/* project overview ------------------------------------------------------------------------- */
.project_overview-table {
  @apply theme-p2;
}
.project_overview-table-row {
  @apply flex justify-between pb-5 mb-5 border-b-[1px] border-b-theme-gray;
}
.project_overview-table-heading {
  @apply w-2/5 font-medium text-theme-gray dark:text-[#aaaaaa];
}
.project_overview-table-desc {
  @apply w-3/5;
}

/* projects list view ----------------------------------------------------------------------------------------------- */
/* .projects_list-wrapper a {
  cursor: url("https://res.cloudinary.com/dkusl18gf/image/upload/v1702651459/eriiikaaa/Cursor_2_ebl9z2.png"),
    auto !important;
} */
.projects_list-wrapper {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 w-full pt-14 gap-x-5 gap-y-3 lg:gap-8 max-w-[1250px] mx-auto;
}
.projects_list-wrapper .test .projects_list2-single,
.test .projects_list2-image-wrapper {
  @apply lg:h-[65vh];
}
.projects_list-wrapper .sub-grid .projects_list2-single,
.sub-grid .projects_list2-image-wrapper {
  @apply lg:h-[calc((65vh-32px)/2)];
}
/* .projects_list-single {
  @apply border-t-[1px] border-t-theme-gray py-5 lg:py-9 flex flex-col lg:flex-row gap-y-2 justify-between lg:items-center;
}
.projects_list-left {
  @apply flex gap-5;
}
.projects_list-category {
  @apply theme-p1 pl-8 lg:pl-0;
} */

/* projects list view version 2 --------------------------------------------------------------------- */
.projects_list2-single {
  @apply relative w-full max-w-[1400px] rounded-2xl overflow-hidden mx-auto lg:p-5 theme-p2 lg:shadow-2xl transition-all;
}
.projects_list2-image-wrapper {
  @apply relative lg:absolute top-0 left-0 w-full rounded-2xl overflow-hidden bg-cover bg-center bg-no-repeat aspect-[154/100] lg:aspect-auto group-hover:brightness-90 transition-all;
}
.projects_list2-text-wrapper {
  @apply relative lg:top-0 lg:left-0 flex lg:flex-col gap-2 lg:gap-2 p-5 justify-between items-center lg:items-start z-20;
}
.projects_list2-category {
  @apply text-base;
}
@media only screen and (min-width: 1024px) {
  .projects_list2-single:hover {
    @apply scale-[1.01];
  }

  /* .projects_list2-single:before {
    @apply bg-theme-black opacity-80 rounded-2xl;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    transition: all 0.3s;
    z-index: 10;
  }
  .projects_list2-single:hover:before {
    width: 100%;
  }*/

  .projects_list2-single:hover .lg-view-case-btn {
    @apply w-7 rotate-45 transition delay-75;
  }
}

/* contact form styling ------------------------------------------------------------------- */
.contact-form {
  @apply max-w-[950px] mx-auto pt-14;
}
.theme-input {
  @apply w-full h-10 relative;
}
.contact-form .textarea {
  @apply h-20;
}
.theme-input input,
.textarea textarea {
  @apply block w-full h-full border-b-theme-gray border-b-2 bg-transparent;
}
.theme-input input:focus ~ label,
.theme-input input:not(:placeholder-shown) ~ label,
.textarea textarea:focus ~ label,
.textarea textarea:not(:placeholder-shown) ~ label {
  @apply -translate-y-6 text-sm text-theme-pink;
}
.textarea textarea {
  resize: none;
  padding-top: 10px;
}
.theme-input label {
  @apply absolute pointer-events-none bottom-2 text-xl transition-all duration-300 ease-linear;
}
.textarea label {
  @apply w-full bottom-10 bg-transparent;
}
.theme-input .input-underline {
  @apply absolute bottom-0 h-[2px] w-full;
}
.theme-input .input-underline:before {
  @apply bg-theme-pink;
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.theme-input input:focus-visible,
.textarea textarea:focus-visible {
  @apply outline-none;
}
.theme-input input:focus ~ .input-underline:before,
.theme-input input:not(:placeholder-shown) ~ .input-underline:before,
.textarea textarea:focus ~ .input-underline:before,
.textarea textarea:not(:placeholder-shown) ~ .input-underline:before {
  transform: scale(1);
}
::placeholder {
  @apply !text-theme-white dark:!text-theme-black;
}
::-ms-input-placeholder {
  @apply !text-theme-white dark:!text-theme-black;
}

/* test  */
.skill-carousel {
  width: 100%;
  margin: 0 auto;
}
.slick-slide {
  padding: 0 10px; /* Adjust the spacing as needed */
}
.language-logo {
  @apply text-center aspect-square rounded-2xl !flex flex-col justify-center items-center shadow-lg;
  background: conic-gradient(#222222, #000000);
}
.dark .language-logo {
  background: conic-gradient(#f0f0f0, #fff);
}

.language-logo img,
.language-logo svg {
  @apply w-2/5 lg:pt-7;
}
.language-logo span {
  @apply text-theme-white dark:text-theme-black pt-5 theme-p1 hidden lg:flex;
}
.active-language-description {
  text-align: center;
  margin-top: 20px;
}
